import { Route, Routes } from "react-router-dom";

import MonthOverview from "./MonthOverview";
import WeekOverviewDetail from "./WeekOverviewDetail/WeekOverviewDetail";
import WeekOverviewGeneric from "./WeekOverviewGeneric";

const CalendarRoutes = () => {
  return (
    <Routes>
      <Route index element={<MonthOverview />} />
      <Route path="/tydenni-detail" element={<WeekOverviewDetail />} />
      <Route path="/tydenni-hromadny" element={<WeekOverviewGeneric />} />
    </Routes>
  );
};

export default CalendarRoutes;
