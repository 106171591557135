import type { StudyDayGenericType } from "api/generated";

import { getDayNameFromIndex } from "utils/calendarDateFormatting";

export const generateEmptyTimetable = () => {
  const result: StudyDayGenericType[] = [];

  for (let i = 1; i < 8; i++) {
    result.push({
      dayName: getDayNameFromIndex(i),
      duration: 0,
      startAt: "00:00",
    });
  }

  return result;
};
