import { PhoneValidator } from "components";

const PhoneValidation = () => {
  return (
    <main className={"h-100 d-flex flex-column justify-content-between justify-content-sm-start text-center"}>
      <div className="page px-3">
        <div className="steps-indicator mb-40px">
          <div className={"dot"} />
          <div className={"dot active"} />
          <div className={"dot"} />
        </div>

        <PhoneValidator redirectBackUrl="/onboarding/preference" redirectNextUrl="/onboarding/rozvrh" />
      </div>
    </main>
  );
};

export default PhoneValidation;
