import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

import { CurrentStreak, Loading } from "components";
import { BadgesTab, RewardsTab, StatisticsTab } from "./Tabs";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { processApiError } from "utils/processApiError";

import { useGetStatisticsDataQuery } from "api/generated";
type TStatisticTab = "Odznaky" | "Statistiky" | "Odměny";
const baseTabs: TStatisticTab[] = ["Odznaky", "Statistiky"];
const rewardsTitle = "Odměny";

const Statistics = () => {
  const { t } = useTranslation(["common", "settings"]);
  const [tabs, setTabs] = useState(baseTabs);

  const { data, isLoading, error: apiError } = useGetStatisticsDataQuery();

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    if (data.parentWatch.rewards.length > 0) {
      setTabs([...baseTabs, rewardsTitle]);
    }
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <>
      <div className={"page-header page-header--has-tabs"}>
        <Container className={"container-mw-md"}>
          <div className="page-title__heading">
            <div>
              <Breadcrumb>
                <li className={"breadcrumb-item"}>
                  <Link to={"/"}>
                    <IconArrowBack />
                    {t("pageNameHome")}
                  </Link>
                </li>
              </Breadcrumb>
              <h1 className={"mb-0"}>{t("pageNameMyAchievements")}</h1>
            </div>
            <CurrentStreak />
          </div>
        </Container>
      </div>

      <Container fluid className={"page-container pt-25px pt-sm-45px px-0"}>
        <Tab.Container defaultActiveKey={tabs[0]} mountOnEnter={true}>
          <Row>
            <Container className={"container-mw-md"}>
              <Nav>
                {tabs.map(tab => {
                  return (
                    <Nav.Item key={tab}>
                      <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Container>
          </Row>

          <Tab.Content className={"pt-45px"}>
            <Tab.Pane key={tabs[0]} eventKey={tabs[0]}>
              <BadgesTab data={data?.checkAchievements} />
            </Tab.Pane>

            <Tab.Pane key={tabs[1]} eventKey={tabs[1]}>
              <StatisticsTab />
            </Tab.Pane>

            {tabs.length > 2 ? (
              <Tab.Pane key={tabs[2]} eventKey={tabs[2]}>
                <RewardsTab data={data?.parentWatch} />
              </Tab.Pane>
            ) : null}
          </Tab.Content>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Statistics;
