import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { QueryStatus } from "@reduxjs/toolkit/query";

import { Loading } from "components";

import { useUpdatePhoneNumberMutation, useSendPhoneValidationCodeMutation } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { getPhoneValidationStatus, selectTempPhoneNumber, validatePhoneNumber } from "store/slices/user";

import { processApiError } from "utils/processApiError";

import { PHONE_VALIDATION_CODE_LENGTH } from "const";

interface Props {
  redirectBackUrl: string;
  redirectNextUrl: string;
}

const PhoneValidator: FC<Props> = ({ redirectBackUrl, redirectNextUrl }) => {
  const { t } = useTranslation(["common", "onboarding"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tempPhone = useAppSelector(selectTempPhoneNumber);
  const initialValidationStatus = useAppSelector(getPhoneValidationStatus);

  const [resendCode, { isLoading: isResending, error: apiResendMutationError }] = useUpdatePhoneNumberMutation();
  const [sendCode, { isLoading: isSending, error: apiSendMutationError, status: validationMutationStatus }] =
    useSendPhoneValidationCodeMutation();

  const [isResendModalShown, setResetModalVisibility] = useState(false);
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [codeValues, updateCodeValues] = useState<string[]>(new Array(PHONE_VALIDATION_CODE_LENGTH).fill(""));
  const [isConfirmDisabled, setDisabledConfirm] = useState(true);

  const inputs = useRef<Array<HTMLInputElement | null>>(new Array(codeValues.length).fill(null));
  const isLoadingMutation = useRef(false);

  useEffect(() => {
    if (apiResendMutationError === undefined) return;

    processApiError(apiResendMutationError);
  }, [apiResendMutationError]);

  useEffect(() => {
    if (apiSendMutationError === undefined) return;

    processApiError(apiSendMutationError);
  }, [apiSendMutationError]);

  useEffect(() => {
    if (initialValidationStatus === "accepted" && validationMutationStatus === QueryStatus.uninitialized) {
      navigate(redirectBackUrl);
    }
  }, [initialValidationStatus]);

  useEffect(() => {
    if (!isResendDisabled) return;

    const timer = setTimeout(() => {
      setResendDisabled(false);
    }, 60000);

    return () => {
      clearTimeout(timer);
    };
  }, [isResendDisabled]);

  const handleChange = (indexStr: string | undefined, char: string) => {
    const isValidChar = /^([^\W_]|)/.test(char);
    if (char.length > 1 || !isValidChar) return;

    const index = Number(indexStr);
    if (index.toString() !== indexStr || index < 0 || index >= codeValues.length) return;

    const el = inputs.current[index];
    if (el === null || el === undefined) return;

    handleCodeChange(char, index);

    el.blur();
    if (index + 1 < inputs.current.length) {
      const newEl = inputs.current[index + 1];
      if (newEl === null) return;

      newEl.focus();
      newEl.select();
    }
  };

  const handleResendPhone = () => {
    if (isLoadingMutation.current || typeof tempPhone !== "string") return;

    setResetModalVisibility(true);
    resendCode({ phoneNumber: tempPhone }).catch(error => {
      console.error(error);
    });
  };

  const handleCodeChange = (value: string, index: number) => {
    updateCodeValues(prev => {
      prev[index] = value.toString();

      setDisabledConfirm(prev.some(code => code.length === 0));

      return [...prev];
    });
  };

  const sendValidationCode = () => {
    if (isConfirmDisabled || isLoadingMutation.current) return;

    isLoadingMutation.current = true;
    setDisabledConfirm(true);

    sendCode({ code: codeValues.join("") })
      .then(response => {
        if ("data" in response) {
          const validationStatus = response.data.validatePhone.status;

          if (validationStatus === "accepted") {
            navigate(redirectNextUrl);
          }

          dispatch(validatePhoneNumber(validationStatus));
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        isLoadingMutation.current = false;
        setDisabledConfirm(false);
      });
  };

  const getInput = (value: string | undefined, index: number) => (
    <Col xs={2} key={`code_validator_${index}`}>
      <Form.Control
        ref={(ref: HTMLInputElement) => {
          inputs.current[index] = ref;
        }}
        autoFocus={index === 0}
        value={value}
        type="text"
        maxLength={1}
        onFocus={event => {
          event.target.select();
        }}
        onKeyDown={event => {
          if (event.key === "+" || event.key === "." || event.key === "e") event.preventDefault();
          const key = event.key === "Delete" || event.key === "Backspace" ? "" : event.key;
          handleChange(event.currentTarget.dataset.id, key);
        }}
        size={"lg"}
        data-id={index}
        className="text-center form-control--focus-azure"
      />
    </Col>
  );

  return (
    <>
      <div className="page__content justify-content-center">
        <h1 className={"h2 mb-3"}>{t("phoneValidation.heading", { ns: "onboarding" })}</h1>
        <p>{t("phoneValidation.text", { ns: "onboarding" })}</p>
        <Form>
          <Row className={"gx-4px"}>{inputs.current.map((_element, index) => getInput(codeValues[index], index))}</Row>
        </Form>

        <Button
          variant={"link"}
          onClick={handleResendPhone}
          className="p-0 mt-3 mx-auto"
          disabled={isResendDisabled || isLoadingMutation.current}
        >
          {t("resendCode")}
        </Button>
      </div>

      <div className={"page__footer"}>
        <Button
          className={"w-100 text-uppercase mt-auto"}
          onClick={sendValidationCode}
          disabled={isConfirmDisabled || isSending || isResending}
        >
          {t("continue")}
        </Button>
      </div>

      <Modal
        show={isResendModalShown}
        onHide={() => {
          setResetModalVisibility(false);
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>{isResending ? <Loading /> : <p className={"text-center"}>Kód byl znovu odeslán</p>}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className={"w-100 text-uppercase"}
            onClick={() => {
              setResetModalVisibility(false);
            }}
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhoneValidator;
