import type { FC, MouseEvent } from "react";
import Calendar from "react-calendar";
import { createSearchParams, useNavigate } from "react-router-dom";

import { CalendarDay } from "features";

import type { TTimetableDays } from "store/slices/timetable";

import { useDateNames } from "utils/hooks/useDateNames";
import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";
import { isDateInPastWeek } from "utils/validateWeekRange";

import logo from "images/logos/tonatrenujes.svg";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

const getDateFromForQuery = (date: Date) => {
  const prevMondayOfGivenDate = getPrevMonday(date);

  return {
    from: formatDate(prevMondayOfGivenDate),
  };
};

interface Props {
  date: Date;
  tests: string[];
  timetableData: TTimetableDays;
}

const CalendarMonth: FC<Props> = ({ date, tests, timetableData }) => {
  const navigate = useNavigate();
  const { monthNames } = useDateNames();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDayClick = (value: Date, _event: MouseEvent<HTMLButtonElement>) => {
    const params = getDateFromForQuery(value);

    navigate({
      pathname: "/kalendar/tydenni-detail",
      search: createSearchParams(params).toString(),
    });
  };

  const isClickDisabled = ({ date }: { date: Date }) => {
    return isDateInPastWeek(date);
  };

  const createCalendarDay = ({ date }: { date: Date }) => {
    const dateString = formatDate(date);
    const dayData = timetableData[dateString];

    const isToday = date.getTime() === today.getTime();
    const isFuture = date > today;
    const withTest = tests.includes(dateString);
    const plannedExerciseDuration = timetableData[dateString]?.duration;
    const isFinished = dayData?.sessionFinished ?? false;
    const isPlannedExercise = (isFuture || isToday) && plannedExerciseDuration > 0 && !isFinished;
    const isDeadline = formatDate(date) === DATE_OF_ENTRANCE_EXAMINATION;

    return (
      <CalendarDay
        date={date}
        isToday={isToday}
        isFinished={isFinished}
        withTest={withTest}
        isDeadline={isDeadline}
        isPlannedExercise={isPlannedExercise}
        exerciseDuration={plannedExerciseDuration}
      />
    );
  };

  return (
    <div className={"calendar"}>
      <img src={logo} className={"calendar__print-logo d-none d-print-block"} style={{ height: "44px" }} />

      <h2 className={"calendar-header h4 text-center"}>{monthNames[date.getMonth()]}</h2>
      <Calendar
        activeStartDate={date}
        formatDay={() => ""}
        locale="cs"
        onClickDay={handleDayClick}
        showNeighboringMonth={false}
        showNavigation={false}
        tileContent={createCalendarDay}
        tileDisabled={isClickDisabled}
        defaultView="month"
        maxDetail="month"
        minDetail="month"
        view="month"
      />
    </div>
  );
};

export default CalendarMonth;
