import { ReactComponent as StrawberrySVG } from "images/icons/strawberry.svg";

const CalendarInfo = () => {
  return (
    <div className="calendar-info">
      <StrawberrySVG />
      <div>
        <strong>Jak se počítá řada?</strong> Za každý den, kdy splníš svůj cíl procvičování, ti přidáme jahodu. O
        všechny jahody přijdeš, pokud v daný den do půlnoci nedokončíš naplánovaný trénink. Nemůžeš? Včas si přehoď
        trénink na jiný den v týdnu. <strong>Jak si přehodit trénink?</strong> Stačí kliknout na daný den v kalendáři.
      </div>
    </div>
  );
};

export default CalendarInfo;
