import type { FC } from "react";
import { Alert } from "react-bootstrap";

interface Props {
  remainingTime: number | string | null;
  allPlannedText: string;
  needsPlanningText: string;
}

const RemainingTimeCard: FC<Props> = ({ remainingTime, allPlannedText, needsPlanningText }) => {
  if (remainingTime === null) {
    return (
      <Alert variant={"success"} className={"my-3"}>
        {allPlannedText}
      </Alert>
    );
  }

  return (
    <Alert variant={"danger"} className={"my-3"}>
      {needsPlanningText}
    </Alert>
  );
};

export default RemainingTimeCard;
