import type { CSSProperties, FC } from "react";

import { sessionResultEnum } from "utils/calculateSessionResult";

import { useTranslation } from "react-i18next";
import { useDateNames } from "../../utils/hooks/useDateNames";
import { useSessionDescription } from "../../utils/hooks/useSessionDescription";

import SnailSVG from "images/icons/snail.svg";
import StrawberrySVG from "images/icons/strawberry.svg";

const getBackgroundImageStyles = (
  isPlannedExercise: boolean,
  isFinished: boolean,
  isFuture: boolean,
  isToday: boolean,
) => {
  const base: CSSProperties = {
    paddingBottom: "10px",
    height: "38px",
    width: "100%",
  };

  if (isFuture || (isToday && !isFinished) || !isPlannedExercise) return base;

  const styles: CSSProperties = {
    ...base,
    backgroundImage: `url(${StrawberrySVG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    color: "white",
  };

  if (isFinished) {
    styles.backgroundImage = `url(${StrawberrySVG})`;
    return styles;
  }

  styles.backgroundImage = `url(${SnailSVG})`;
  return styles;
};

interface Props {
  date: Date;
  isToday: boolean;
  isFinished: boolean;
  withTest: boolean;
  isDeadline: boolean;
  isPlannedExercise: boolean;
  exerciseDuration: number;
  exerciseRealDuration?: number;
  longDate?: boolean;
  longStatusDescription?: boolean;
}

const CalendarDayBig: FC<Props> = ({
  date,
  isToday,
  isFinished,
  withTest,
  isDeadline,
  isPlannedExercise,
  exerciseDuration,
  exerciseRealDuration = 0,
  longDate = false,
  longStatusDescription = false,
}) => {
  const renderSessionDescription = () => {
    if (!isPlannedExercise) return null;

    return <span className="d-none d-md-inline">{getSessionDescriptionByType(sessionResultEnum.PLANNED)}</span>;
  };

  const { getShortDayNameFromDayIndex } = useDateNames();
  const { getSessionDescriptionByType } = useSessionDescription();

  const { t } = useTranslation(["calendar"]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const dateMidnight = new Date(date.setHours(0, 0, 0, 0));
  const isFuture = dateMidnight.getTime() > today.getTime();
  const shouldTrain = exerciseDuration > 0 || exerciseRealDuration > 0;

  return (
    <div
      className={`react-calendar__month-view__days__day__inner react-calendar__month-view__days__day__inner--high ${
        isToday ? "today" : ""
      }`}
    >
      {isDeadline ? (
        <div className={"label label-pink"}>{t("dayOfDeadline", { ns: "calendar" })}</div>
      ) : withTest ? (
        <div className={"label label-purple"}>{t("dayOfTest", { ns: "calendar" })}</div>
      ) : null}
      <strong className={isDeadline ? "text-pink" : withTest ? "text-purple" : ""}>
        {longDate ? (
          <>
            {getShortDayNameFromDayIndex(date.getDay())}
            <span className="d-none d-md-inline">{` ${date.getDate()}. ${date.getMonth() + 1}.`}</span>
          </>
        ) : (
          date.getDate()
        )}
      </strong>

      {longStatusDescription ? (
        <div className={`status-text`} style={getBackgroundImageStyles(shouldTrain, isFinished, isFuture, isToday)}>
          {(isToday || isFuture) && isPlannedExercise && !isFinished ? (
            <>
              {renderSessionDescription()}
              <br />
              <span className="d-none d-md-inline">
                {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
              </span>
              <span className="d-md-none">
                {exerciseDuration}
                {t("minuteShort", { ns: "common" })}
              </span>
            </>
          ) : null}
        </div>
      ) : null}
      {exerciseDuration > 0 && (
        <time className={isToday ? "text-white" : "text-muted"}>
          {exerciseDuration} {t("minute", { ns: "common", count: exerciseDuration })}
        </time>
      )}
    </div>
  );
};

export default CalendarDayBig;
