import { useEffect, useState } from "react";
import type { FormEvent } from "react";
import { Breadcrumb, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";

import { PhoneInput } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useUpdatePhoneNumberMutation } from "api/generated";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { selectPhoneNumber, setTempPhoneNumber } from "store/slices/user";

import { processApiError } from "utils/processApiError";

import { isValidPhoneNumber } from "utils/phoneValidator";

const PhoneChange = () => {
  const userPhoneNumber = useAppSelector(selectPhoneNumber);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updatePhoneNumberMutation, { error: apiMutationError }] = useUpdatePhoneNumberMutation();
  const { t } = useTranslation(["common", "settings"]);

  const [phoneNumber, setNewPhoneNumber] = useState("");

  useEffect(() => {
    if (typeof userPhoneNumber === "string") {
      setNewPhoneNumber(userPhoneNumber);
    }
  }, [userPhoneNumber]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValidPhoneNumber(phoneNumber)) {
      updatePhoneNumberMutation({ phoneNumber })
        .then(() => {
          dispatch(setTempPhoneNumber(phoneNumber));
          navigate("/nastaveni/overeni-telefonu");
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      // TODO: show some error?
      console.error(`${phoneNumber} is not valid phone number`);
    }
  };

  const handlePhoneChange = (phone: string) => {
    setNewPhoneNumber(phone);
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/nastaveni/propojeni"}>
                <IconArrowBack />
                {t("back")}
              </Link>
            </li>
          </Breadcrumb>
          <h1 className={"mb-0"}>{t("phoneChange.heading", { ns: "settings" })}</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <Form onSubmit={handleSubmit}>
          <PhoneInput
            label={t("phoneChange.text", { ns: "settings" })}
            phoneNumber={phoneNumber}
            onPhoneChange={handlePhoneChange}
          />

          <Row className={"g-8px"}>
            <Col xs={6}>
              <LinkContainer to="/nastaveni/propojeni">
                <Button className={"w-100 text-uppercase"} variant={"outline-secondary"}>
                  {t("dontSave")}
                </Button>
              </LinkContainer>
            </Col>
            <Col xs={6}>
              <Button
                type={"submit"}
                className={"w-100 text-uppercase"}
                disabled={!isValidPhoneNumber(phoneNumber) || phoneNumber === userPhoneNumber}
              >
                {t("saveChanges")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default PhoneChange;
