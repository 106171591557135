import { CurrentStreak } from "components";

import { useAppSelector } from "store/hooks";
import { selectUserAddressing, selectUserName } from "store/slices/user";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

const oneWeekMilliseconds = 1000 * 60 * 60 * 24 * 7;

const WelcomeMessage = () => {
  const userVocativeName = useAppSelector(selectUserAddressing);
  const userName = useAppSelector(selectUserName);

  const name = userVocativeName ?? userName;

  const weeksCount = Math.max(
    Math.floor((new Date(DATE_OF_ENTRANCE_EXAMINATION).getTime() - new Date().getTime()) / oneWeekMilliseconds),
    0,
  );

  return (
    <>
      <div className="page-title__heading">
        <h1 className="mb-28px">
          Ahoj <span className={"text-highlight text-highlight--subtle"}>{name},</span>
        </h1>
        <div>
          <CurrentStreak />
        </div>
      </div>
      <p className="fw-bold">
        je před námi {weeksCount} týdnů do přijímaček a kromě testů nanečisto je třeba se naučit mezi nimi pravidelně
        procvičovat.
      </p>
    </>
  );
};

export default WelcomeMessage;
