import { useEffect, useState } from "react";
import type { ChangeEvent, FC } from "react";
import { Form, FormGroup } from "react-bootstrap";

import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";
import { useDateNames } from "utils/hooks/useDateNames";

const getMinDateForInput = () => {
  const today = new Date();
  const monday = getPrevMonday(today);

  return formatDate(monday);
};

const getInitialDate = () => {
  const today = new Date();

  if (today.getDay() === 6) {
    today.setDate(today.getDate() + 2);
  }

  if (today.getDay() === 0) {
    today.setDate(today.getDate() + 1);
  }

  return formatDate(today);
};
interface Props {
  handleDateChange: (date: string) => void;
  label: string;
}

const TimetableDatePicker: FC<Props> = ({ handleDateChange, label }) => {
  const { getDayNameFromDayIndex } = useDateNames();
  const initialDate = getInitialDate();

  const formatDateForPicker = (dateStr: string) => {
    const date = new Date(dateStr);
    const dayIndex = date.getDay() === 0 ? 7 : date.getDay();

    return `${getDayNameFromDayIndex(dayIndex)} ${date.getDate()}.${date.getMonth() + 1}.`;
  };

  const [value, setValue] = useState(formatDateForPicker(initialDate));

  useEffect(() => {
    handleDateChange(initialDate);
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(formatDateForPicker(event.target.value));
    handleDateChange(event.target.value);
  };

  return (
    <FormGroup className={"mt-20px"}>
      <Form.Label>{label}</Form.Label>
      <div className="date-picker">
        <Form.Control type="text" value={value} readOnly className="border-none" />
        <Form.Control type="date" min={getMinDateForInput()} onChange={onChange} style={{ opacity: 0 }} />
      </div>
    </FormGroup>
  );
};

export default TimetableDatePicker;
