import type { FC } from "react";
import { Link } from "react-router-dom";

import { useAppSelector } from "store/hooks";
import { getStreak } from "store/slices/user";

import { ReactComponent as StrawberrySVG } from "images/icons/strawberry.svg";

interface Props {
  withLink?: boolean;
}

const CurrentStreak: FC<Props> = ({ withLink = true }) => {
  const streak = useAppSelector(getStreak);

  if (withLink) {
    return (
      <Link to={"/kalendar"} className="current-streak">
        <div className="current-streak__content">
          <span className="current-streak__count">{streak}</span>
          <div className="current-streak__icon">
            <StrawberrySVG />
          </div>
        </div>
        {withLink ? <div className="current-streak__link">Moje řada</div> : null}
      </Link>
    );
  }

  return (
    <div className="current-streak">
      <div className="current-streak__content">
        <span className="current-streak__count">{streak}</span>
        <div className="current-streak__icon">
          <StrawberrySVG />
        </div>
      </div>
    </div>
  );
};

export default CurrentStreak;
