import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";

import { Timetable } from "features";

import { useGenericTimetable } from "utils/hooks/useGenericTimetable";
import { useFormatRemainingTime } from "utils/hooks/useFormatRemainingTime";

import { useAppDispatch } from "store/hooks";
import { setGenericTimetable } from "store/slices/timetable";

const TimetablePage = () => {
  const { t } = useTranslation(["common", "onboarding"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formatRemainingTime = useFormatRemainingTime();

  useEffect(() => {
    dispatch(setGenericTimetable([]));
  }, []);

  const { dayTimes, changeTimetable, saveGenericTimetable, remainingTime } = useGenericTimetable();
  const [timetableStart, setTimetableStart] = useState<null | Date>(null);
  const [canContinue, setContinue] = useState(false);

  useEffect(() => {
    setContinue(remainingTime === null && timetableStart !== null);
  }, [remainingTime, timetableStart]);

  const onContinueClick = () => {
    if (canContinue && timetableStart !== null) {
      saveGenericTimetable(timetableStart)
        .then(() => {
          navigate("/onboarding/priklad-uvod");
        })
        .finally(() => ({}));
    }
  };

  const timetableConfiguration = {
    infoBoxPosition: "top" as "top",
    texts: {
      needsPlanning: t("timetable.needsPlanning", {
        ns: "onboarding",
        remaining: formatRemainingTime(remainingTime),
      }),
      allPlanned: t("timetable.allPlanned", { ns: "onboarding" }),
      datePickerLabel: t("timetable.datePickerLabel", { ns: "onboarding" }),
    },
  };

  return (
    <>
      <main className={"h-100 d-flex flex-column justify-content-between justify-content-sm-start"}>
        <div className="page px-3">
          <div className="steps-indicator mb-28px">
            <div className={"dot"} />
            <div className={"dot"} />
            <div className={"dot active"} />
          </div>

          <div className="page__content">
            <h1 className={"h2 mb-3"}>{t("timetable.heading", { ns: "onboarding" })}</h1>
            <Alert variant={"warning"}>{t("timetable.text", { ns: "onboarding" })}</Alert>

            <Timetable
              dateStart={null}
              dayTimes={dayTimes}
              onDayTimeChange={changeTimetable}
              onDateChange={setTimetableStart}
              showDatePicker={true}
              configuration={timetableConfiguration}
            />
          </div>

          <div className="page__footer">
            <Button onClick={onContinueClick} disabled={!canContinue} className={"w-100 text-uppercase mt-40px"}>
              {t("continue")}
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default TimetablePage;
