import { useEffect, useState } from "react";

import type { IDayTimetable } from "features/Timetable/dayTimetable.type";

import { useSetTimetableMutation } from "api/generated";
import type { StudyDayInput } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setTimetable, selectTimetable } from "store/slices/timetable";
import type { TTimetableDays } from "store/slices/timetable";

import { calculateRemainingTime } from "utils/calculateRemainingTimetableTime";
import { processApiError } from "utils/processApiError";

import { formatDate } from "utils/calendarDateFormatting";

export const useTimetable = (dateFrom: string) => {
  const dispatch = useAppDispatch();
  const timetableData = useAppSelector(selectTimetable);
  const [setTimetableMutation, { error: apiMutationError }] = useSetTimetableMutation();

  const [dayTimes, setDayTimes] = useState<IDayTimetable[]>([]);
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(dayTimes));

  useEffect(() => {
    const weekData = filterDayDataByWeek(dateFrom, timetableData);
    setDayTimes(weekData);
    setRemainingTime(calculateRemainingTime(weekData));
  }, [timetableData]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const changeTimetable = (changedTimes: IDayTimetable[]) => {
    setDayTimes(changedTimes);
    setRemainingTime(calculateRemainingTime(changedTimes));
  };

  const saveTimetable = async () => {
    const dateTo = new Date(dateFrom);
    dateTo.setDate(dateTo.getDate() + 6);

    const dataForBE = prepareDataForBE(dayTimes, dateFrom);

    await setTimetableMutation({ timeTable: dataForBE });
    dispatch(setTimetable([{ dateEnd: formatDate(dateTo), dateStart: dateFrom, plan: dayTimes }]));
  };

  return { dayTimes, saveTimetable, changeTimetable, remainingTime };
};

const prepareDataForBE = (dayTimes: IDayTimetable[], dateFrom: string) => {
  const result: StudyDayInput[] = [];
  const date = new Date(dateFrom);

  for (let dayIndex = 1; dayIndex < 8; dayIndex++) {
    const dayData = dayTimes.find(day => day.dayIndex === dayIndex);

    if (dayData === undefined) {
      result.push({
        date: formatDate(date),
        duration: 0,
        startAt: "00:00:00",
      });
      continue;
    }

    const startAt = dayData.startAt.split(":");
    if (startAt[0].length === 1) startAt[0] = `0${startAt[0]}`;
    startAt.push("00");

    result.push({
      date: formatDate(date),
      duration: dayData.duration,
      startAt: startAt.join(":"),
    });

    date.setDate(date.getDate() + 1);
  }

  return result;
};

const filterDayDataByWeek = (date: string, timetable: TTimetableDays) => {
  const result: IDayTimetable[] = [];
  const currentDate = new Date(date);

  for (let i = 0; i < 7; i++) {
    const dayIndex = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
    const formattedDate = formatDate(currentDate);

    const dayData = timetable[formattedDate];

    result.push({
      dayIndex,
      duration: dayData !== undefined ? dayData.duration : 0,
      startAt: dayData !== undefined ? dayData.startAt : "00:00",
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};
