import { useEffect, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import { RemainingTimeCard, TimetableModal, TimetableDatePicker } from "components";
import type { ITimetableModalData } from "components/Modal/TimetableModal/TimetableModal";

import { useAppSelector } from "store/hooks";
import { selectTests } from "store/slices/timetable";

import { useDateNames } from "utils/hooks/useDateNames";
import { calculateRemainingTime } from "utils/calculateRemainingTimetableTime";
import { formatDate } from "utils/calendarDateFormatting";

import type { IDayTimetable } from "./dayTimetable.type";

import { ReactComponent as EditIcon } from "images/icons/edit.svg";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

interface Props {
  dateStart: Date | null;
  dayTimes: IDayTimetable[];
  onDayTimeChange: (data: IDayTimetable[]) => void;
  onDateChange?: (date: Date | null) => void;
  showDatePicker?: boolean;
  configuration: {
    infoBoxPosition: "top" | "bottom";
    texts: {
      needsPlanning: string;
      allPlanned: string;
      datePickerLabel: string;
    };
  };
}

const Timetable: FC<Props> = ({
  dateStart,
  dayTimes,
  onDayTimeChange,
  onDateChange,
  showDatePicker,
  configuration,
}) => {
  const { t } = useTranslation(["common", "onboarding"]);
  const [modalData, setModalData] = useState<null | ITimetableModalData>(null);
  const { getShortDayNameFromDayIndex } = useDateNames();
  const testDates = useAppSelector(selectTests);

  const openModal = (dayIndex: number, duration: number, date: Date | null) => {
    if (modalData !== null) return;
    const start = dayTimes.find(day => day.dayIndex === dayIndex)?.startAt;
    setModalData({ dayIndex, date, duration, startAt: start ?? "00:00" });
  };

  const onModalClose = () => {
    setModalData(null);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, []);

  const onModalConfirm = ({ dayIndex, duration, startAt }: IDayTimetable) => {
    if (modalData === null) return;

    const changed = dayTimes.map(day => {
      if (day.dayIndex === dayIndex) {
        return {
          dayIndex,
          duration,
          startAt,
        };
      }

      return day;
    });

    onDayTimeChange(changed);
    setModalData(null);
  };

  const handleDateChange = (dateStr: string) => {
    if (onDateChange !== undefined) {
      onDateChange(new Date(dateStr));
    }
  };

  const remainingTime = calculateRemainingTime(dayTimes, true);

  return (
    <>
      {configuration.infoBoxPosition === "top" ? (
        <RemainingTimeCard
          remainingTime={remainingTime}
          allPlannedText={configuration.texts.allPlanned}
          needsPlanningText={configuration.texts.needsPlanning}
        />
      ) : null}

      <div className={"agenda"}>
        {dayTimes.map(dayData => {
          const dayName = getShortDayNameFromDayIndex(dayData.dayIndex);
          const currentDay = dateStart === null ? null : new Date(dateStart.getTime());

          let isTest = false;
          let isDeadline = false;
          if (currentDay !== null) {
            currentDay.setDate(currentDay.getDate() + dayData.dayIndex - 1);

            const formattedDayDate = formatDate(currentDay);
            isTest = testDates.find(testDate => testDate === formattedDayDate) !== undefined;
            isDeadline = formattedDayDate === DATE_OF_ENTRANCE_EXAMINATION;
          }

          return (
            <div className={"agenda-day"} key={dayName}>
              <div className={"text-center d-grid"}>
                {isDeadline ? (
                  <div className={"label-wrapper"}>
                    <div className={"label label-pink"}>{t("dayOfDeadline", { ns: "calendar" })}</div>
                  </div>
                ) : isTest ? (
                  <div className={"label-wrapper"}>
                    <div className={"label label-purple"}>{t("dayOfTest", { ns: "calendar" })}</div>
                  </div>
                ) : null}

                <strong className={isDeadline ? "text-pink" : isTest ? "text-purple" : ""}>
                  <span className={"agenda-day-name"}>{dayName}</span>
                </strong>
                <span className={"agenda-date"}>
                  {currentDay === null ? "" : `${currentDay.getDate()}. ${currentDay.getMonth() + 1}.`}
                </span>
              </div>

              {dayData.duration > 0 ? (
                <Button
                  className={"ml-auto my-auto agenda-time-set"}
                  onClick={() => {
                    openModal(dayData.dayIndex, dayData.duration, currentDay);
                  }}
                  size={"sm"}
                  variant={""}
                >
                  <strong>
                    {dayData.duration} {t("minute", { ns: "common", count: dayData.duration })}
                  </strong>{" "}
                  {t("from")} {dayData.startAt}
                  <EditIcon />
                </Button>
              ) : (
                <div className={"ml-auto d-flex align-items-center gap-8px"}>
                  <div className={"text-muted"}>{t("add")}:</div>

                  <Button
                    size={"sm"}
                    variant={"light"}
                    className={"btn-icon btn-shadow border-0 text-cyan fw-normal"}
                    onClick={() => {
                      openModal(dayData.dayIndex, 30, currentDay);
                    }}
                  >
                    +30 {t("minute", { ns: "common", count: 30 })}
                  </Button>

                  <Button
                    size={"sm"}
                    variant={"light"}
                    className={"btn-icon btn-shadow border-0 text-cyan fw-normal"}
                    onClick={() => {
                      openModal(dayData.dayIndex, 60, currentDay);
                    }}
                  >
                    +60 {t("minute", { ns: "common", count: 60 })}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {showDatePicker === true && remainingTime === null && (
        <TimetableDatePicker handleDateChange={handleDateChange} label={configuration.texts.datePickerLabel} />
      )}

      {configuration.infoBoxPosition === "bottom" ? (
        <RemainingTimeCard
          remainingTime={remainingTime}
          allPlannedText={configuration.texts.allPlanned}
          needsPlanningText={configuration.texts.needsPlanning}
        />
      ) : null}

      {modalData !== null && <TimetableModal data={modalData} onClose={onModalClose} onConfirm={onModalConfirm} />}
    </>
  );
};

export default Timetable;
