import type { IDayTimetable } from "features/Timetable/dayTimetable.type";
import { TIMETABLE_REQUESTED_TIME } from "const";

export const calculateRemainingTime = (dayTimes: IDayTimetable[], rawMinutes: boolean = false) => {
  let totalTime = 0;

  for (const day of dayTimes) {
    totalTime += day.duration;
  }

  if (totalTime >= TIMETABLE_REQUESTED_TIME) return null;

  const remainingTime = TIMETABLE_REQUESTED_TIME - totalTime;
  const hours = Math.floor(remainingTime / 60);
  const minutes = remainingTime % 60;

  if (rawMinutes) {
    return remainingTime;
  } else {
    return `${hours}:${minutes === 0 ? "00" : minutes}`;
  }
};
