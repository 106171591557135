import { useTranslation } from "react-i18next";

export const useFormatRemainingTime = () => {
  const { t } = useTranslation(["common"]);

  const formatHours = (time: number) => {
    const hours = Math.floor(time / 60);
    if (hours === 0) return "";

    return `${hours} ${t("hour", { ns: "common", count: hours })}`;
  };

  const formatMinutes = (time: number) => {
    const minutes = time % 60;
    if (minutes === 0) return "";

    return `${minutes} ${t("minute", { ns: "common", count: minutes })}`;
  };

  const getSeparator = (time: number) => {
    return time / 60 > 0 && time % 60 > 0 ? ` ${t("and")} ` : "";
  };

  const formatRemainingTime = (remainingTime: string | number | null) => {
    if (typeof remainingTime === "string") return remainingTime;
    if (remainingTime === null) return "";

    return `${formatHours(remainingTime)}${getSeparator(remainingTime)}${formatMinutes(remainingTime)}`;
  };

  return formatRemainingTime;
};
