import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { useGetTimetableQuery, useLazyGetDashboardDataQuery } from "api/generated";

import { Timetable } from "features";

import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setTimetable, setTests } from "store/slices/timetable";
import { getTimetableCreateDate } from "store/slices/user";

import { useDateNames } from "utils/hooks/useDateNames";
import { useQuery } from "utils/hooks/useQuery";
import { useTimetable } from "utils/hooks/useTimetable";
import { getNearestSunday, getNextSunday, validateMonday } from "utils/validateWeekRange";
import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";
import { useFormatRemainingTime } from "utils/hooks/useFormatRemainingTime";
import { processApiError } from "utils/processApiError";

const WeekOverviewDetail = () => {
  const { t } = useTranslation(["common", "calendar"]);
  const navigate = useNavigate();
  const formatRemainingTime = useFormatRemainingTime();

  const monday = formatDate(getPrevMonday(new Date()));
  const sunday = formatDate(getNearestSunday(new Date()));
  const dashboardTimetableCreatedDate = useAppSelector(getTimetableCreateDate);
  const dashboardTimetableDateFrom =
    dashboardTimetableCreatedDate === null || monday > dashboardTimetableCreatedDate
      ? monday
      : dashboardTimetableCreatedDate;

  const dispatch = useAppDispatch();
  const { monthNamesGenitive } = useDateNames();

  const { getSearchParam } = useQuery();
  const { from } = validateMonday(getSearchParam("from"));
  const dateFrom = from ?? getPrevMonday(new Date());
  const dateTo = new Date(dateFrom);
  dateTo.setDate(dateTo.getDate() + 7);

  const {
    data: timetableData,
    isLoading,
    error: apiError,
    refetch,
  } = useGetTimetableQuery({
    dateFrom: formatDate(dateFrom),
    dateTo: formatDate(dateTo),
  });

  const [refetchDashboard] = useLazyGetDashboardDataQuery();

  useEffect(() => {
    if (timetableData !== undefined && !isLoading) {
      refetch().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (timetableData?.timetable?.timetable !== undefined && timetableData.timetable.timetable.length > 0) {
      dispatch(setTimetable(timetableData.timetable.timetable));

      const tests: string[] = [];
      for (const testDate of timetableData.testPresence) {
        tests.push(testDate.dateTime.split(" ")[0]);
      }
      dispatch(setTests(tests));
    }
  }, [timetableData?.timetable]);

  const { dayTimes, changeTimetable, saveTimetable, remainingTime } = useTimetable(
    formatDate(from ?? getPrevMonday(new Date())),
  );

  const [timetableStart, setTimetableStart] = useState<null | Date>(null);
  const [isConfirmButtonDisable, setDisabledConfirmButton] = useState(false);

  useEffect(() => {
    setDisabledConfirmButton(remainingTime !== null || (from === null && timetableStart === null) || isLoading);
  }, [timetableStart, remainingTime, isLoading]);

  const formatDateForHumans = (date: Date) => {
    return `${date.getDate()}. ${monthNamesGenitive[date.getMonth()]}`;
  };

  const handleSave = () => {
    setDisabledConfirmButton(true);

    saveTimetable()
      .then(() => {
        refetchDashboard({ timetableFrom: dashboardTimetableDateFrom, timetableTo: sunday }).catch(console.error);
        navigate("/kalendar");
      })
      .finally(() => ({}));
  };

  const timetableConfiguration = {
    infoBoxPosition: "bottom" as "bottom",
    texts: {
      needsPlanning: t("needsPlanning", {
        ns: "calendar",
        remaining: formatRemainingTime(remainingTime),
      }),
      allPlanned: t("allPlanned", { ns: "calendar" }),
      datePickerLabel: t("datePickerLabel", { ns: "calendar" }),
    },
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/kalendar"}>
                <IconArrowBack />
                {t("monthOverview", { ns: "calendar" })}
              </Link>
            </li>
          </Breadcrumb>
          <h1>{t("weekCalendar", { ns: "calendar" })}</h1>
          <h2 className={"h3 mb-0"}>
            {from !== null && `${formatDateForHumans(from)} - ${formatDateForHumans(getNextSunday(from))}`}
          </h2>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Timetable
          dateStart={from}
          dayTimes={dayTimes}
          onDayTimeChange={changeTimetable}
          onDateChange={setTimetableStart}
          configuration={timetableConfiguration}
        />
        <Row className={"py-2"}>
          <Col xs={6}>
            <LinkContainer to="/kalendar">
              <Button className={"w-100 text-uppercase"} variant={"outline-secondary"}>
                {t("dontSave")}
              </Button>
            </LinkContainer>
          </Col>
          <Col xs={6}>
            <Button className={"w-100 text-uppercase"} onClick={handleSave} disabled={isConfirmButtonDisable}>
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WeekOverviewDetail;
