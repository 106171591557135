import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import { Timetable } from "features";

import { useGetTimetableGenericQuery, useLazyGetDashboardDataQuery } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setGenericTimetable } from "store/slices/timetable";
import { getTimetableCreateDate } from "store/slices/user";

import { useGenericTimetable } from "utils/hooks/useGenericTimetable";
import { generateEmptyTimetable } from "utils/timetableHelpers";
import { useFormatRemainingTime } from "utils/hooks/useFormatRemainingTime";
import { processApiError } from "utils/processApiError";
import { getNearestSunday } from "utils/validateWeekRange";
import { formatDate, getPrevMonday } from "utils/calendarDateFormatting";

const WeekOverviewGeneric = () => {
  const { t } = useTranslation(["common", "calendar"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formatRemainingTime = useFormatRemainingTime();

  const monday = formatDate(getPrevMonday(new Date()));
  const sunday = formatDate(getNearestSunday(new Date()));
  const dashboardTimetableCreatedDate = useAppSelector(getTimetableCreateDate);
  const dashboardTimetableDateFrom =
    dashboardTimetableCreatedDate === null || monday > dashboardTimetableCreatedDate
      ? monday
      : dashboardTimetableCreatedDate;

  const { data, isLoading, error: apiError, refetch } = useGetTimetableGenericQuery();
  const [refetchDashboard] = useLazyGetDashboardDataQuery();

  const { dayTimes, changeTimetable, saveGenericTimetable, remainingTime } = useGenericTimetable();
  const [timetableStart, setTimetableStart] = useState<null | Date>(null);
  const [isConfirmButtonDisable, setDisabledConfirmButton] = useState(false);

  useEffect(() => {
    if (data !== undefined && !isLoading) {
      refetch().finally(() => ({}));
    }
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data?.timetableGenericCurrent !== undefined && data.timetableGenericCurrent.plan.length === 7) {
      dispatch(setGenericTimetable(data.timetableGenericCurrent.plan));
      return;
    }

    dispatch(setGenericTimetable(generateEmptyTimetable()));
  }, [data]);

  useEffect(() => {
    setDisabledConfirmButton(remainingTime !== null || timetableStart === null || isLoading);
  }, [timetableStart, remainingTime, isLoading]);

  const handleSave = () => {
    if (timetableStart === null) return;
    setDisabledConfirmButton(true);

    saveGenericTimetable(timetableStart)
      .then(() => {
        refetchDashboard({ timetableFrom: dashboardTimetableDateFrom, timetableTo: sunday }).catch(console.error);
        navigate("/kalendar");
      })
      .finally(() => ({}));
  };

  const timetableConfiguration = {
    infoBoxPosition: "bottom" as "bottom",
    texts: {
      needsPlanning: t("needsPlanning", {
        ns: "calendar",
        remaining: formatRemainingTime(remainingTime),
      }),
      allPlanned: t("allPlanned", { ns: "calendar" }),
      datePickerLabel: t("datePickerLabel", { ns: "calendar" }),
    },
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/kalendar"}>
                <IconArrowBack />
                {t("monthOverview", { ns: "calendar" })}
              </Link>
            </li>
          </Breadcrumb>
          <h1>{t("weekCalendar", { ns: "calendar" })}</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <Timetable
            dateStart={null}
            dayTimes={dayTimes}
            onDayTimeChange={changeTimetable}
            onDateChange={setTimetableStart}
            showDatePicker={true}
            configuration={timetableConfiguration}
          />
        )}
        <Row className={"py-2"}>
          <Col xs={6}>
            <LinkContainer to="/kalendar">
              <Button className={"w-100 text-uppercase"} variant={"outline-secondary"}>
                {t("dontSave")}
              </Button>
            </LinkContainer>
          </Col>
          <Col xs={6}>
            <Button className={"w-100 text-uppercase"} onClick={handleSave} disabled={isConfirmButtonDisable}>
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WeekOverviewGeneric;
