import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { useGetTimetableGenericQuery } from "api/generated";

import { Loading } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { Timetable } from "features";

import { useAppDispatch } from "store/hooks";
import { setGenericTimetable } from "store/slices/timetable";

import { useGenericTimetable } from "utils/hooks/useGenericTimetable";
import { generateEmptyTimetable } from "utils/timetableHelpers";
import { useFormatRemainingTime } from "utils/hooks/useFormatRemainingTime";
import { processApiError } from "utils/processApiError";

const TimetableChange = () => {
  const { t } = useTranslation(["common", "settings"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, error: apiError, refetch } = useGetTimetableGenericQuery();
  const { dayTimes, changeTimetable, saveGenericTimetable, remainingTime } = useGenericTimetable();
  const formatRemainingTime = useFormatRemainingTime();

  const [timetableStart, setTimetableStart] = useState<null | Date>(null);
  const [canSaveTimetable, setCanSaveTimetable] = useState(false);

  useEffect(() => {
    if (data !== undefined && !isLoading) {
      refetch().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data?.timetableGenericCurrent !== undefined && data.timetableGenericCurrent.plan.length === 7) {
      dispatch(setGenericTimetable(data.timetableGenericCurrent.plan));
      return;
    }

    dispatch(setGenericTimetable(generateEmptyTimetable()));
  }, [data]);

  useEffect(() => {
    setCanSaveTimetable(remainingTime === null && timetableStart !== null);
  }, [remainingTime, timetableStart]);

  const handleSave = () => {
    if (canSaveTimetable && timetableStart !== null) {
      saveGenericTimetable(timetableStart)
        .then(() => {
          navigate("/nastaveni");
        })
        .finally(() => ({}));
    }
  };

  const timetableConfiguration = {
    infoBoxPosition: "bottom" as "bottom",
    texts: {
      needsPlanning: t("timetable.needsPlanning", {
        ns: "onboarding",
        remaining: formatRemainingTime(remainingTime),
      }),
      allPlanned: t("timetable.allPlanned", { ns: "settings" }),
      datePickerLabel: t("timetable.datePickerLabel", { ns: "settings" }),
    },
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/nastaveni"}>
                <IconArrowBack />
                {t("pageNameSettings", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>
          <h1 className={"mb-0"}>{t("changeMyTimetable", { ns: "settings" })}</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <Timetable
            dateStart={null}
            dayTimes={dayTimes}
            onDayTimeChange={changeTimetable}
            onDateChange={setTimetableStart}
            showDatePicker={true}
            configuration={timetableConfiguration}
          />
        )}

        <Row className={"py-2"}>
          <Col xs={6}>
            <LinkContainer to="/kalendar">
              <Button className={"w-100 text-uppercase"} variant={"outline-secondary"} disabled={isLoading}>
                {t("dontSave")}
              </Button>
            </LinkContainer>
          </Col>
          <Col xs={6}>
            <Button className={"w-100 text-uppercase"} onClick={handleSave} disabled={!canSaveTimetable || isLoading}>
              {t("saveChanges")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TimetableChange;
