import { useEffect, useState } from "react";

import type { IDayTimetable } from "features/Timetable/dayTimetable.type";

import { useSetTimetableGenericMutation } from "api/generated";
import type { StudyDayGenericInput } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { setGenericTimetable, selectGenericTimetable } from "store/slices/timetable";

import { calculateRemainingTime } from "utils/calculateRemainingTimetableTime";
import { formatDate, getDayNameFromIndex } from "utils/calendarDateFormatting";
import { processApiError } from "utils/processApiError";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

export const useGenericTimetable = () => {
  const dispatch = useAppDispatch();
  const timetableData = useAppSelector(selectGenericTimetable);
  const [setTimetableGenericMutation, { error: apiMutationError }] = useSetTimetableGenericMutation();

  const [dayTimes, setDayTimes] = useState(timetableData);
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(dayTimes));

  useEffect(() => {
    setRemainingTime(calculateRemainingTime(dayTimes));
  }, [dayTimes]);

  useEffect(() => {
    if (timetableData.length === 7) {
      setDayTimes(timetableData);
    }
  }, [timetableData]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const changeTimetable = (changedTimes: IDayTimetable[]) => {
    setDayTimes(changedTimes);
  };

  const saveGenericTimetable = async (from: Date) => {
    try {
      const dataForBE = prepareDataForBE(dayTimes);

      await setTimetableGenericMutation({
        from: formatDate(from),
        to: DATE_OF_ENTRANCE_EXAMINATION,
        timeTable: dataForBE,
      });
      dispatch(setGenericTimetable(dataForBE));
    } catch (error) {
      console.error(error);
    }
  };

  return { dayTimes, saveGenericTimetable, changeTimetable, remainingTime };
};

export const prepareDataForBE = (dayTimes: IDayTimetable[]) => {
  const result: StudyDayGenericInput[] = [];

  for (let i = 1; i < 8; i++) {
    const dayData = dayTimes.find(day => day.dayIndex === i);

    if (dayData === undefined) {
      result.push({
        dayName: getDayNameFromIndex(i),
        duration: 0,
        startAt: "00:00:00",
      });
      continue;
    }

    const startAt = dayData.startAt.split(":");
    if (startAt[0].length === 1) startAt[0] = `0${startAt[0]}`;
    startAt.push("00");

    result.push({
      dayName: getDayNameFromIndex(i),
      duration: dayData.duration,
      startAt: startAt.join(":"),
    });
  }

  return result;
};
