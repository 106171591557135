import { useEffect, useState, useRef } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { IconBook } from "components/Icons/IconBook";
import { IconStrawberry } from "components/Icons/Strawberry";
import { IconBookmark } from "components/Icons/Bookmark";
import IconEnvelope from "../Icons/IconEnvelope/IconEnvelope";
import IconTrophy from "../Icons/IconTrophy/IconTrophy";
import IconSettings from "../Icons/IconSettings/IconSettings";
import IconHelp from "../Icons/IconHelp/IconHelp";

import { useAppSelector } from "store/hooks";
import { getFrom } from "store/slices/envelopeNavigation";

const Menu = () => {
  const { t } = useTranslation(["common"]);
  const { pathname } = useLocation();
  const envelopeOrigin = useAppSelector(getFrom);

  const [activeKey, setActiveKey] = useState("/");
  const envelopeFrom = useRef<string | null>(null);

  useEffect(() => {
    if (envelopeOrigin !== envelopeFrom.current) {
      envelopeFrom.current = envelopeOrigin;
    }

    if (pathname.includes("/ulohy/obalka")) {
      setActiveKey(envelopeFrom.current ?? "/ulohy");
      return;
    }

    setActiveKey(`/${pathname.split("/")[1]}`);
  }, [pathname, envelopeOrigin]);

  return (
    <Nav className={"flex-column w-100"} activeKey={activeKey}>
      <NavItem>
        <LinkContainer to="/">
          <Nav.Link>
            <IconEnvelope />
            {t("pageNameHome")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/ulohy">
          <Nav.Link>
            <IconBook />
            {t("pageNameEnvelopesAndTasks")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/ulozene">
          <Nav.Link>
            <IconBookmark />
            {t("pageNameBookmarks")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/statistiky">
          <Nav.Link>
            <IconTrophy />
            {t("pageNameMyAchievements")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/kalendar">
          <Nav.Link>
            <IconStrawberry />
            {t("pageNameCalendar")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <hr />

      <NavItem>
        <LinkContainer to="/nastaveni">
          <Nav.Link>
            <IconSettings />
            {t("pageNameSettings")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/dotazy">
          <Nav.Link>
            <IconHelp />
            {t("pageNameHelp")}
          </Nav.Link>
        </LinkContainer>
      </NavItem>

      <NavItem>
        <LinkContainer to="/dev">
          <Nav.Link>
            <IconSettings />
            Dev
          </Nav.Link>
        </LinkContainer>
      </NavItem>
    </Nav>
  );
};

export default Menu;
