import { useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import type { IDayTimetable } from "features/Timetable/dayTimetable.type";

import { useDateNames } from "utils/hooks/useDateNames";

import { mockSessionDurations, mockSessionStarts, mockSessionDefaultStart } from "./mockData";
import { Form, FormLabel } from "react-bootstrap";
import Select from "react-select";
import type { SingleValue } from "react-select";

export interface ITimetableModalData {
  date: Date | null;
  dayIndex: number;
  duration: number;
  startAt: string;
}

interface Props {
  data: ITimetableModalData;
  onClose: () => void;
  onConfirm: ({ duration, startAt }: IDayTimetable) => void;
}

const TimetableModal: FC<Props> = ({ data, onClose, onConfirm }) => {
  const { date, dayIndex, duration, startAt } = data;

  const { t } = useTranslation(["common"]);
  const { getDayNameFromDayIndex } = useDateNames();

  const [isDayActive, setDayActive] = useState(data.duration !== null);
  const [selectedData, setSelectedData] = useState<IDayTimetable>({
    dayIndex,
    duration,
    startAt: startAt === "00:00" ? mockSessionDefaultStart : startAt,
  });

  const title = `${getDayNameFromDayIndex(dayIndex)}${
    date !== null ? ` ${date.getDate()}. ${date.getMonth() + 1}` : ""
  }`;

  const handleCheck = () => {
    if (isDayActive) {
      setSelectedData(data => {
        return {
          dayIndex: data.dayIndex,
          duration: 0,
          startAt: "00:00",
        };
      });
    }

    setDayActive(checked => !checked);
  };

  const handleSessionLengthChange = (event: SingleValue<{ value: number; label: string }>) => {
    setSelectedData(data => {
      return { ...data, duration: Number(event?.value) };
    });
  };

  const handleSessionStartChange = (event: SingleValue<{ value: string; label: string }>) => {
    setSelectedData(data => {
      return { ...data, startAt: String(event?.value) };
    });
  };

  const handleConfirm = () => {
    onConfirm(selectedData);
  };

  const mockSessionDurationsOptions = mockSessionDurations.map(duration => {
    return {
      value: duration,
      label: `${duration} min`,
    };
  });

  const mockSessionStartsOptions = mockSessionStarts.map(startAt => {
    const parts = startAt.split(":");

    if (parts[0].length === 1) {
      parts[0] = `0${parts[0]}`;
    }

    return {
      value: parts.join(":"),
      label: startAt,
    };
  });

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body>
        <h4 className={"mb-20px"}>{title}</h4>

        <Form>
          <Form.Check type="switch" checked={isDayActive} onChange={handleCheck} label={t("wantToPractice")} />
          {isDayActive && (
            <>
              <FormLabel className={"mt-3"} htmlFor={"sessionLength"}>
                {t("howLong")}
              </FormLabel>

              <Select
                name="sessionLength"
                id="sessionLength"
                className={"react-select-container"}
                classNamePrefix="react-select"
                value={mockSessionDurationsOptions.find(duration => duration.value === selectedData.duration)}
                options={mockSessionDurationsOptions}
                onChange={handleSessionLengthChange}
              />

              <FormLabel className={"mt-3"} htmlFor={"sessionStart"}>
                {t("startingAt")}
              </FormLabel>
              <p className={"text-muted"}>{t("startingAtDescription")}</p>
              <Select
                name="sessionStart"
                id="sessionStart"
                className={"react-select-container"}
                classNamePrefix="react-select"
                defaultValue={mockSessionStartsOptions.find(startAt => startAt.value === selectedData.startAt)}
                options={mockSessionStartsOptions}
                onChange={handleSessionStartChange}
              />
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm} className={"w-100 text-uppercase"}>
          {t("save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimetableModal;
