import { useEffect, useState } from "react";
import type { FormEvent } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading, ParentRewardModal, RewardsOverview } from "components";

import { useGetParentRewardsQuery, useUpdateParentRewardsMutation } from "api/generated";
import type { ParentRewardInput } from "api/generated";

import { useDateNames } from "utils/hooks/useDateNames";
import { processApiError } from "utils/processApiError";

import { DATE_OF_ENTRANCE_EXAMINATION, REWARDS_MONTH_TYPE, REWARDS_POINTS_TYPE } from "const";

import { ReactComponent as EditIcon } from "images/icons/edit.svg";

export interface IMonth {
  month: number;
  text: string;
}

export interface IPoints {
  targetScore?: number | null;
  text: string;
}

interface IUserData {
  name: string;
  message: string;
  rewards: {
    monthRewards: IMonth[];
    pointsRewards: IPoints[];
  };
  initialLoad: boolean;
}

export type TModalTypes = typeof REWARDS_MONTH_TYPE | typeof REWARDS_POINTS_TYPE;

const generateDummyMonthData = () => {
  const septemberId = 8;

  const endMonth = new Date(DATE_OF_ENTRANCE_EXAMINATION).getMonth() + 12;

  const monthsData = [];
  for (let month = septemberId; month <= endMonth; month++) {
    monthsData.push({ month: month > 11 ? month - 12 : month, text: "" });
  }

  return monthsData;
};

const defaultData = {
  name: "",
  message: "",
  rewards: {
    monthRewards: generateDummyMonthData(),
    pointsRewards: [],
  },
  initialLoad: true,
};

const getShortName = (name: string | null) => {
  if (typeof name !== "string") return "";

  const parts = name.split(" ");

  if (parts.length < 2) {
    return parts[0] ?? "";
  }

  const firstName = parts[0];
  const lastName = parts[parts.length - 1];

  return `${firstName} ${lastName[0].toLocaleUpperCase()}.`;
};

const Rewards = () => {
  const { t } = useTranslation(["parentRewards"]);
  const { monthNames } = useDateNames();
  const navigate = useNavigate();

  const [activeModalType, setModalType] = useState<{ type: TModalTypes | null; value: number | null }>({
    type: null,
    value: null,
  });
  const [userData, setUserData] = useState<IUserData>(defaultData);
  const [message, setMessage] = useState<string>("");

  const { data, isLoading, error } = useGetParentRewardsQuery();
  const [updateRewards, { error: apiMutationError }] = useUpdateParentRewardsMutation();

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  const processMonthData = (
    originalData: IMonth[],
    data: Array<{ month?: number | undefined | null; text: string }>,
  ) => {
    const result = [...originalData];

    for (const item of data) {
      const month = (item.month ?? 0) - 1;

      if (month < 0) continue;

      const savedMonth = result.find(original => original.month === month);

      if (savedMonth === undefined) continue;

      savedMonth.text = item.text;
    }

    return result;
  };

  useEffect(() => {
    if (typeof error === "object" && error !== null && "message" in error) {
      console.error(error);
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateMessage();
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [message]);

  useEffect(() => {
    if (data === undefined) return;

    setMessage(data.parentWatch.message ?? "");
    setUserData(savedUserData => {
      return {
        name: getShortName(data.parentWatch.user.name ?? null),
        message: data.parentWatch.message ?? "",
        rewards: {
          monthRewards: processMonthData(
            savedUserData.rewards.monthRewards,
            data.parentWatch.rewards.filter(reward => reward.type === REWARDS_MONTH_TYPE),
          ),
          pointsRewards: data.parentWatch.rewards
            .filter(reward => reward.type === REWARDS_POINTS_TYPE)
            .sort((a, b) => (a.targetScore ?? 0) - (b.targetScore ?? 0)),
        },
        initialLoad: false,
      };
    });
  }, [data]);

  useEffect(() => {
    if (userData.initialLoad) return;

    const rewards = parseRewards(userData.rewards.monthRewards, userData.rewards.pointsRewards);

    updateData(userData.message, rewards);
  }, [userData]);

  const parseRewards = (monthRewards: IMonth[], pointsRewards: IPoints[]) => {
    const rewards: ParentRewardInput[] = [];

    for (const month of monthRewards) {
      rewards.push({ text: month.text, type: REWARDS_MONTH_TYPE, month: month.month + 1, targetScore: -1 });
    }

    for (const points of pointsRewards) {
      rewards.push({ text: points.text, type: REWARDS_POINTS_TYPE, targetScore: points.targetScore, month: -1 });
    }

    return rewards;
  };

  const updateData = (message: string, rewards: ParentRewardInput[]) => {
    updateRewards({ rewards: { message, rewards } })
      .then(data => {
        if (!("data" in data)) {
          console.error(data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setModalType({ type: null, value: null });
      });
  };

  const showModal = (type: TModalTypes, value: number | null) => {
    setModalType({
      type,
      value,
    });
  };

  const closeModal = () => {
    setModalType({ type: null, value: null });
  };

  const submitModal = ({ monthRewards, pointsRewards }: { monthRewards: IMonth[]; pointsRewards: IPoints[] }) => {
    setUserData(userData => {
      return {
        ...userData,
        rewards: {
          monthRewards,
          pointsRewards,
        },
        initialLoad: false,
      };
    });
  };

  const updateMessage = () => {
    setUserData(userData => {
      return {
        ...userData,
        message,
        initialLoad: false,
      };
    });
  };

  const handleMessageChange = (message: string) => {
    setMessage(message);
  };

  if (isLoading) return <Loading />;

  if (userData === null) {
    return null;
  }

  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop"}>
        <Container className={"container-mw-md"}>
          <h1>Odměny: {userData.name}</h1>
          <p>
            Níže naleznete dvě tabulky, u kterých můžete zadat externí odměnu, která bude ještě více napomáhat k
            dosažení výsledků. Pokud Vás žádná odměna nenapadá, na konci stránky máme pár tipů, tak na ně můžete
            kouknout. :)
          </p>
          <p>
            Není povinností externí odměnu vyplňovat, jedná se pouze o podpůrný prostředek, jak ještě více namotivovat
            děti ke studiu. Mějte na paměti, že tyto informace budou v aplikaci následně použity, takže o nich Vaše děti
            budou vědět. Jak bude odměna zobrazena v aplikaci můžete také vidět níže.
          </p>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Row>
          <Col>
            <p>Zde můžete také napsat motivační vzkaz, který se propíše do aplikace (nepovinné):</p>
            <Form
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <Form.Control
                placeholder={"Držíme Ti palce, víme, že to zvládneš. Máma a táta"}
                value={message}
                onChange={e => {
                  handleMessageChange(e.target.value);
                }}
                as="textarea"
                rows={2}
              />
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h2>Nastavené odměny</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Splnění plánu</h3>
            <Table
              className="table-custom"
              onSubmit={(event: FormEvent<HTMLTableElement>) => {
                event.preventDefault();
              }}
            >
              <tbody>
                {userData.rewards.monthRewards.map(item => (
                  <tr key={item.month}>
                    <td>{monthNames[item.month]}</td>
                    <td className="parent-rewards__reward">
                      {item.text.length > 0 ? (
                        <>
                          {item.text}
                          <Button
                            variant="link"
                            onClick={() => {
                              showModal(REWARDS_MONTH_TYPE, item.month);
                            }}
                          >
                            <EditIcon />
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="link"
                          onClick={() => {
                            showModal(REWARDS_MONTH_TYPE, item.month);
                          }}
                        >
                          přidat
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3>Dosažení bodů v testech nanečisto</h3>
            <Table className="table-custom">
              <tbody>
                {userData.rewards.pointsRewards
                  .filter((reward): reward is { targetScore: number; text: string } => "targetScore" in reward)
                  .map(item => (
                    <tr key={item.targetScore}>
                      <td>{item.targetScore}</td>
                      <td className="parent-rewards__reward">
                        {item.text}
                        <Button
                          variant="link"
                          onClick={() => {
                            showModal(REWARDS_POINTS_TYPE, item.targetScore);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={2}>
                    <Button
                      variant="link"
                      onClick={() => {
                        showModal(REWARDS_POINTS_TYPE, null);
                      }}
                    >
                      přidat
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Pár tipů k externí motivaci (záleží vždy na jednotlivci, co preferuje a co má rád, nicméně přinášíme
              alespoň pár obecných tipů):
            </p>

            <ul>
              <li>společný výlet</li>
              <li>aquapark/dinopark/Iqlandia</li>
              <li>hračka nebo věc, kterou si přeje</li>
              <li>společný sport (kolo, brusle, výšlap atd.)</li>
              <li>společenská hra</li>
            </ul>
          </Col>
        </Row>

        <hr className={"my-25px"} />

        <Row>
          <h2>{t("Preview")}</h2>
          <RewardsOverview message={userData.message} rewards={userData.rewards} />
        </Row>
      </Container>

      {typeof activeModalType.type === "string" ? (
        <ParentRewardModal
          isShown={typeof activeModalType.type === "string"}
          modalType={activeModalType.type}
          defaultValue={activeModalType.value}
          monthRewards={userData.rewards.monthRewards}
          pointsRewards={userData.rewards.pointsRewards}
          closeModal={closeModal}
          submitModal={submitModal}
        />
      ) : null}
    </>
  );
};

export default Rewards;
