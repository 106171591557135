import { Alert, ButtonGroup, Col, Container, DropdownButton, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

import packWaiting from "images/icons/pack-waiting.svg";
import packBuy from "images/icons/pack-buy.svg";

import EnvelopeStackIcon from "components/EnvelopeStackIcon/EnvelopeStackIcon";
/* import { AchievementIcon } from "../../../../components"; */

import tipImage from "images/icons/tip.svg";
import { CalendarWeek } from "../../../../features";

const calendarData = [
  {
    date: "2023-10-30",
    startAt: "10:00",
    duration: 60,
    durationReal: 120,
  },
  {
    date: "2023-10-31",
    startAt: null,
    duration: null,
    durationReal: null,
  },
  {
    date: "2023-11-01",
    startAt: "12:00",
    duration: 30,
    durationReal: 15,
  },
  {
    date: "2023-11-02",
    startAt: null,
    duration: null,
    durationReal: null,
  },
  {
    date: "2023-11-03",
    startAt: null,
    duration: null,
    durationReal: null,
  },
  {
    date: "2023-11-04",
    startAt: null,
    duration: null,
    durationReal: null,
  },
  {
    date: "2023-11-05",
    startAt: "22:00",
    duration: 120,
    durationReal: null,
  },
];

const Dashboard01 = () => {
  return (
    <>
      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Row>
          <Col>
            <h1 className="mb-28px">
              Ahoj <span className={"text-highlight text-highlight--subtle"}>Marku</span>,
            </h1>
            <p className="fw-bold">
              je před námi 7 týdnů do přijímaček a kromě testů nanečisto je třeba se naučit mezi nimi pravidelně
              procvičovat. Tenhle týden máš za úkol:
            </p>

            <div className="well">
              <div className="pt-6px">
                <div className="px-3 pt-8px">
                  <CalendarWeek data={{ studyDays: calendarData, tests: ["2023-11-04"] }} />
                </div>
              </div>
              <hr />
              <div className="pt-12px px-28px pb-32px">
                <Row className="g-12px">
                  <Col xs={"auto"}>
                    <Image src={tipImage} />
                  </Col>
                  <Col>
                    <p className={"fw-bold mb-8px"}>Dnes už máš procvičování za sebou, ale...</p>
                    <p className={"mb-12px"}>
                      Kdybys měl trochu času, mrkni třeba na 3 ROVNICE, chtělo by je trochu procvičit!
                    </p>
                    <ButtonGroup className={"btn-group-dropdown"}>
                      <Button className={"text-uppercase"}>Procvičovat rovnice</Button>
                      <DropdownButton as={ButtonGroup} title={""} align={"start"} placement={"bottom"}>
                        <Button className={"text-uppercase"}>Procvičovat poměry</Button>
                        <Button className={"text-uppercase"}>Procvičovat celá čísla</Button>
                        <Button className={"text-uppercase"}>Namixujte mi to 👻</Button>
                      </DropdownButton>
                    </ButtonGroup>
                  </Col>
                </Row>
              </div>
            </div>

            <ul className={"todo mt-25px"}>
              <li className={"complete"}>Projít si úvodní procvičování s 2 příklady</li>
              <li>
                <Link to={"#"}>Nastav si školy, na které se chceš přihlásit</Link>
              </li>
              <li>Dokonči tento týden všechna 4 procvičování</li>
            </ul>

            <h2 className={"mb-3 mt-25px"}>Vyřazeno z procvičování</h2>
            <Alert variant={"danger"}>
              <p>
                Nutné upravit text: Jako vysvětlení že je to mimo procvičování, musí se to doučit a pak až to bude aspoň
                trochu chápat, ať si to dá zpátky do procvičování.
              </p>

              <div className={"tags"}>
                <span className={"tag"}>Rovnice</span>
                <span className={"tag"}>Poměr</span>
                <span className={"tag"}>Zaokrouhlování</span>
                <span className={"tag"}>Celá čísla</span>
                <span className={"tag"}>Velká násobilka</span>
                <a href="javascript:void(0);" className={"tag"}>
                  +7
                </a>
              </div>
            </Alert>

            <h2 className={"mb-3 mt-25px"}>Moje obálky (30 úloh)</h2>

            <Row className={"g-20px"}>
              <Col lg={8}>
                <Row className={"g-2"}>
                  <Col xs={4}>
                    <div className="envelope">
                      <div className="envelope__label envelope__label--red">10 úloh</div>
                      <EnvelopeStackIcon type={"red"} percentage={0.5} />
                      <p className="envelope__description">Zatím ti nejdou 🤷‍♀️</p>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="envelope">
                      <div className="envelope__label envelope__label--orange">20 úloh</div>
                      <EnvelopeStackIcon type={"orange"} percentage={0.75} />
                      <p className="envelope__description">Ještě procvičuj☝️</p>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="envelope">
                      <div className="envelope__label envelope__label--green">0 úloh</div>
                      <EnvelopeStackIcon type={"green"} />
                      <p className="envelope__description">Umíš levou zadní 🙂</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row className="flex-nowrap g-8px">
                  <Col className={"flex-grow-0"}>
                    <Image src={packWaiting} />
                  </Col>
                  <Col>
                    20 testových sad čeká, až je absolvuješ v rámci testů nanečisto. Poté se Ti zpřístupní i zde v
                    aplikaci. <Link to={"#"}>Jak to funguje?</Link>
                  </Col>
                </Row>
                <hr className={"border-top-1px"} />
                <Row className="flex-nowrap g-8px">
                  <Col className={"flex-grow-0"}>
                    <Image src={packBuy} />
                  </Col>
                  <Col>
                    10 dalších testů si <Link to={"#"}>můžeš ještě dokoupit</Link>.
                  </Col>
                </Row>
              </Col>
            </Row>

            <h2 className={"mb-3 mt-25px"}>Poslední získané odznaky</h2>
            <Row className={"g-2"}>
              <Col xs={6} md={3}>
                <div className="achievement">
                  {/* <AchievementIcon colorVariant={"orange"} icon={"calendar"} subIcon={2} /> */}
                  <p>
                    Tohle ocenění jsi obdržel/a za procvičování <strong>dva týdny podle plánu</strong>!
                  </p>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="achievement">
                  {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                  <p>
                    Tohle ocenění jsi obdržel/a za <strong>nejdelší procvičování</strong>!
                  </p>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="achievement">
                  {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                  <p>
                    Tohle ocenění jsi obdržel/a za <strong>nejdelší procvičování</strong>!
                  </p>
                </div>
              </Col>
              <Col xs={6} md={3}>
                <div className="achievement">
                  {/* <AchievementIcon colorVariant={"lightblue"} icon={"stopwatch"} /> */}
                  <p>
                    Tohle ocenění jsi obdržel/a za <strong>nejdelší procvičování</strong>!
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className={"text-center mt-3"}>
            <Link to={"#"}>Prozkoumej vše</Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard01;
