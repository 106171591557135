import { useEffect, useState } from "react";
import type { FC, ReactElement } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { Loading } from "components";
import type { IDayTimetable } from "features/Timetable/dayTimetable.type";

import { useGetCurrentUserQuery, useSetTimetableGenericMutation, useLazyGetStreakQuery } from "api/generated";
import type { UserType } from "api/generated";

import { useAppDispatch } from "store/hooks";
import { setUser, setStreak, setTimetableCreatedDate } from "store/slices/user";

import { formatDate } from "utils/calendarDateFormatting";
import { processApiError } from "utils/processApiError";
import { prepareDataForBE } from "utils/hooks/useGenericTimetable";

import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

const DEFAULT_GENERIC_TIMETABLE: IDayTimetable[] = [
  {
    dayIndex: 1,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 2,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 3,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 4,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 5,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 6,
    duration: 30,
    startAt: "17:00",
  },
  {
    dayIndex: 7,
    duration: 30,
    startAt: "17:00",
  },
];

interface Props {
  children: ReactElement;
}

const AuthenticatedArea: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: userData, isLoading: isUserLoading, isError: isUserError, error: userError } = useGetCurrentUserQuery();
  const [setTimetableGenericMutation, { error: apiMutationError }] = useSetTimetableGenericMutation();
  const [getStreaks, { error: lazyStreaksError }] = useLazyGetStreakQuery();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userError === undefined) return;

    processApiError(userError);
  }, [userError]);

  useEffect(() => {
    if (lazyStreaksError === undefined) return;

    processApiError(lazyStreaksError);
  }, [lazyStreaksError]);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  useEffect(() => {
    if (isUserLoading) return;

    if (userData?.currentUser === undefined) return;

    dispatch(setUser(userData.currentUser));
    setSentryScope(userData.currentUser);

    const firstTimetableDate = [...userData.timetableGenericAll].sort((a, b) =>
      a.created.toString().localeCompare(b.created.toString()),
    )[0]?.created;

    const firstDayForTimetable =
      typeof firstTimetableDate === "string" ? formatDate(new Date(firstTimetableDate)) : formatDate(new Date());

    dispatch(setTimetableCreatedDate(firstDayForTimetable));

    if (pathname !== "/") {
      getStreaks({ streakFrom: firstDayForTimetable, streakTo: formatDate(new Date()) })
        .then(response => {
          if ("data" in response) {
            dispatch(setStreak(response.data?.timetable.daysFulfillsPlan ?? 0));
          }
        })
        .catch(console.error);
    }

    if (userData.currentUser.onboarding.finished) {
      if (typeof firstTimetableDate !== "string") {
        setTimetableGenericMutation({
          from: firstDayForTimetable,
          to: DATE_OF_ENTRANCE_EXAMINATION,
          timeTable: prepareDataForBE(DEFAULT_GENERIC_TIMETABLE),
        })
          .catch(console.error)
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }

      return;
    }

    if (userData.timetableGenericAll.length > 0) {
      navigate("/onboarding/priklad-uvod");
    } else if (userData.currentUser.phoneValidationStatus === "accepted") {
      navigate("/onboarding/rozvrh");
    } else if (
      typeof userData.currentUser.preferredSubject === "string" &&
      userData.currentUser.preferredSubject.length > 0 &&
      typeof userData.currentUser.phoneNumber === "string" &&
      userData.currentUser.phoneNumber.length > 0
    ) {
      navigate("/onboarding/overeni-telefonu");
    } else if (userData.currentUser.onboarding.introduced) {
      navigate("/onboarding/preference");
    } else {
      navigate("/onboarding/uvod/1");
    }

    setIsLoading(false);
  }, [isUserLoading]);

  const setSentryScope = (user: UserType) => {
    const scope = Sentry.getCurrentScope();

    scope.setUser({
      email: user.email ?? undefined,
    });
  };

  return (
    <>
      {isUserError ? (
        <div className={"fullpage bg-primary-subtle"}>
          <BgWaveTop className={"wave__top"} />

          <div className={"d-flex m-auto align-items-center gx-3"}>
            <h4 className={"mb-0"}>Nastala chyba během načítání dat uživatele!</h4>
          </div>
        </div>
      ) : isLoading ? (
        <div className={"fullpage bg-primary-subtle"}>
          <BgWaveTop className={"wave__top"} />

          <Loading />
        </div>
      ) : userData !== undefined ? (
        children
      ) : (
        <div className={"fullpage bg-primary-subtle"}>
          <BgWaveTop className={"wave__top"} />

          <div className={"d-flex m-auto align-items-center gx-3"}>
            <h4 className={"mb-0"}>Nepodařilo se načist data uživatele!</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthenticatedArea;
