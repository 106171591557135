import { Fragment, useEffect, useState } from "react";
import type { FC } from "react";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isMobile } from "utils/isMobile";

import type { WorstTopicsFragment } from "api/generated";

interface Props {
  topics: WorstTopicsFragment[];
}

const ExerciseButtons: FC<Props> = ({ topics }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const [buttons, setButtons] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const buttons: JSX.Element[] = [];

    for (const topic of topics) {
      const id = topic.topic?.id;
      if (typeof id !== "string") continue;

      buttons.push(createTopicButton(id, topic.topic?.name ?? ""));
    }
    buttons.push(createMixButton());

    if (isMobile()) {
      buttons.push(createMhdButton());
    }

    setButtons(buttons);
  }, [topics]);

  const createTopicButton = (topicId: string, topicName: string) => {
    return (
      <Button
        className="text-uppercase"
        onClick={() => {
          redirectTopic(topicId);
        }}
      >
        {t("btn.trainTopic", { ns: "exercise", topicName })}
      </Button>
    );
  };

  const createMhdButton = () => {
    return (
      <Button className={"text-uppercase"} onClick={redirectMhd}>
        {t("btn.mixItUpMhd", { ns: "exercise" })}
      </Button>
    );
  };

  const createMixButton = () => {
    return (
      <Button className={"text-uppercase"} onClick={redirectMix}>
        {t("btn.mixItUp", { ns: "exercise" })}
      </Button>
    );
  };

  const redirectMix = () => {
    navigate("/procvicovani/mix");
  };

  const redirectMhd = () => {
    navigate("/procvicovani/mhd");
  };

  const redirectTopic = (topicId: string) => {
    navigate(`/procvicovani/okruh-slaby/${topicId}`);
  };

  return (
    <>
      <ButtonGroup className={"btn-group-dropdown"}>
        {buttons[0]}
        <DropdownButton as={ButtonGroup} title={""} align={"start"} placement={"bottom"}>
          {buttons.slice(1).map((button, index) => {
            return <Fragment key={index}>{button}</Fragment>;
          })}
        </DropdownButton>
      </ButtonGroup>
    </>
  );
};

export default ExerciseButtons;
