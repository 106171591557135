import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { StudyDayGenericType, TimetableType } from "api/generated";

import type { RootState } from "store/store";

import { formatDate, dayNameToDayIndex, getPrevMonday } from "utils/calendarDateFormatting";

import { DATE_OF_ENTRANCE_EXAMINATION } from "const";

const initializeTimetable = () => {
  const start = getPrevMonday(new Date());

  const end = new Date(DATE_OF_ENTRANCE_EXAMINATION);
  const newData: TTimetableDays = {};

  // eslint-disable-next-line
  while (start <= end) {
    const formatted = formatDate(start);
    newData[formatted] = { duration: 0, durationReal: null, startAt: "00:00", date: formatted, sessionFinished: false };

    start.setDate(start.getDate() + 1);
  }

  return newData;
};

export interface TStudyDayInput {
  date: string;
  duration: number;
  durationReal: number | null;
  startAt: string;
  sessionFinished: boolean;
}

export interface TTimetable {
  dateEnd?: string | null;
  dateStart?: string | null;
  plan: TStudyDayInput[];
}

export type TTimetableDays = Record<string, TStudyDayInput>;

export interface TTimetableGenericDays {
  dayIndex: number;
  duration: number;
  startAt: string;
}

interface ITimetableState {
  timetable: TTimetableDays;
  genericTimetable: TTimetableGenericDays[];
  tests: string[];
}

const initialState: ITimetableState = {
  timetable: initializeTimetable(),
  genericTimetable: [],
  tests: [],
};

const timetableSlice = createSlice({
  name: "timetable",
  initialState,
  reducers: {
    setTimetable: (state, action: PayloadAction<TimetableType[]>) => {
      for (const timetable of action.payload) {
        for (const studyDay of timetable.plan) {
          if (typeof studyDay.startAt !== "string" || typeof studyDay.date !== "string") continue;

          const formattedStart = studyDay.startAt === null ? null : studyDay.startAt.split(":").slice(0, 2).join(":");
          const bla = {
            duration: typeof studyDay.duration === "number" ? studyDay.duration : 0,
            durationReal: typeof studyDay.durationReal === "number" ? studyDay.durationReal : null,
            startAt: formattedStart ?? "00:00",
            date: studyDay.date,
            sessionFinished: studyDay.sessionFinished ?? false,
          };
          state.timetable[studyDay.date] = bla;
        }
      }
    },
    setGenericTimetable: (state, action: PayloadAction<StudyDayGenericType[]>) => {
      const data = action.payload;
      const transformed: TTimetableGenericDays[] = [];

      for (let dayIndex = 1; dayIndex < 8; dayIndex++) {
        const initialDay = {
          dayIndex,
          duration: 0,
          startAt: "00:00",
        };
        transformed.push(initialDay);
      }

      for (const day of data) {
        const dayIndex = dayNameToDayIndex(day.dayName);
        if (dayIndex !== undefined) {
          transformed[dayIndex - 1] = {
            dayIndex,
            duration: day.duration ?? 0,
            startAt: day.startAt.split(":").slice(0, 2).join(":"),
          };
        }
      }
      state.genericTimetable = transformed;
    },
    setTests: (state, action: PayloadAction<string[]>) => {
      state.tests = action.payload;
    },
  },
});

export default timetableSlice.reducer;
export const { setGenericTimetable, setTimetable, setTests } = timetableSlice.actions;

export const selectTimetable = (state: RootState) => state.timetable.timetable;

export const selectGenericTimetable = (state: RootState) => state.timetable.genericTimetable;
export const selectTests = (state: RootState) => state.timetable.tests;
