import type { FC } from "react";
import { Badge, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import { ResultBoxExercise } from "components";

import type { IAssignmentResult, IOption } from "store/slices/exercise";
import { useTranslation } from "react-i18next";

interface IResultObj {
  assignmentId: string;
  result: IAssignmentResult<IOption | string>;
  index: number;
}

interface Props {
  results: Record<string, IAssignmentResult<IOption | string>>;
  envelopes: Record<string, { newEnvelope: string; oldEnvelope: string }>;
  onContinue?: () => void;
}

const Results: FC<Props> = ({ results, envelopes, onContinue }) => {
  const navigate = useNavigate();

  const correctAssignments: IResultObj[] = [];
  const incorrectAssignments: IResultObj[] = [];

  const { t } = useTranslation(["exercise"]);

  Object.entries(results).forEach(([assignmentId, result], index) => {
    const obj = {
      assignmentId,
      result,
      index,
    };

    if (result.status === "not_taken") return;

    result.status === "correct" ? correctAssignments.push(obj) : incorrectAssignments.push(obj);
  });

  const handleContinueClick = () => {
    if (onContinue !== undefined) {
      onContinue();
      return;
    }

    navigate("/");
  };
  return (
    <>
      <div className={"page-header page-header--has-tabs page-header--no-tabs-desktop mb-sm-8px"}>
        <Container className={"container-mw-md"}>
          <h1>{t("header.checkYourResults")}</h1>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        <Tab.Container defaultActiveKey={incorrectAssignments.length > 0 ? "first" : "second"}>
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="first">
                {t("tab.wrongAnswers")}{" "}
                <Badge bg={incorrectAssignments.length > 0 ? "danger" : ""} className={"badge-circular"}>
                  {incorrectAssignments.length}
                </Badge>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                {t("tab.correctAnswers")}{" "}
                <Badge bg={correctAssignments.length === 0 ? "" : "success"} className={"badge-circular"}>
                  {correctAssignments.length}
                </Badge>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first" className={incorrectAssignments.length === 0 ? "pt-20px pb-40px" : ""}>
              {incorrectAssignments.length === 0 ? (
                <p className="text-center mx-5">{t("evaluation.allCorrect", { ns: "exercise" })}</p>
              ) : (
                <Nav className={"nav-menu nav-menu--results"}>
                  {incorrectAssignments.map(obj => {
                    return (
                      <ResultBoxExercise
                        key={obj.assignmentId}
                        result={obj.result}
                        index={obj.index}
                        type={obj.result.status}
                        assignmentId={obj.assignmentId}
                        envelopes={envelopes[obj.assignmentId]}
                        isSelfEvalDisabled={true}
                      />
                    );
                  })}
                </Nav>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="second" className={correctAssignments.length === 0 ? "pt-20px pb-40px" : ""}>
              {correctAssignments.length === 0 ? (
                <p className="text-center mx-5">{t("evaluation.allWrong", { ns: "exercise" })}</p>
              ) : (
                <Nav className={"nav-menu nav-menu--results"}>
                  {correctAssignments.map(obj => {
                    return (
                      <ResultBoxExercise
                        key={obj.assignmentId}
                        result={obj.result}
                        index={obj.index}
                        type={obj.result.status}
                        assignmentId={obj.assignmentId}
                        envelopes={envelopes[obj.assignmentId]}
                        isSelfEvalDisabled={true}
                      />
                    );
                  })}
                </Nav>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className={"thin yellow my-sm-4"} />

        <Row className={"pb-20px"}>
          <Col className="text-center pb-32px">
            <Button className="btn-mobile-width" onClick={handleContinueClick}>
              {t("btn.closeResults")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Results;
